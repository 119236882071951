<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.actionTitle"
              placeholder="活动名称"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.goodsIdList"
              placeholder="参与活动商品ID"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        <a-button v-hasPermission="'goods:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">
          新增
        </a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="masterImage" slot-scope="text, record">
        <div v-if="record.images" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="record.images|firstImage "
              fit="contain"
              :preview-src-list="record.images.split(/[,，、\r\n;；]/)"
          />
        </div>
      </template>
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('validStatus', 'color')">{{
            record.status | dictName("validStatus")
          }}
        </a-tag>
      </template>
      <template slot="platform" slot-scope="text, record">
        <a-tag :color="record.platform | dictName('goodsPlatform', 'color')">{{
            record.platform | dictName("goodsPlatform")
          }}
        </a-tag>
      </template>

      <template slot="hotTop" slot-scope="text, record">
        <a-switch :checked="text==1" @change="changeTopState(record,'hotTop')" :loading="showLoading"/>
      </template>
      <template slot="isTop" slot-scope="text, record">
        <a-switch :checked="text==1" @change="changeTopState(record,'isTop')" :loading="showLoading"/>
      </template>
      <template slot="goodsTitle" slot-scope="text, record">
        <div>
          <h3>{{ record.goodsTitle }}</h3>
          <div style="color:#999;zoom:0.9" v-if="record.subTitle">{{ record.subTitle }}</div>
        </div>
      </template>

      <template slot="price" slot-scope="text, record">
        <div class="red">￥{{ record.price }}</div>
        <div style="text-decoration: line-through;margin-left:2px">￥{{ record.orgPrice }}</div>
      </template>

      <template slot="operation" slot-scope="text, record">
        <a-dropdown
            v-hasAnyPermission="['goods:edit', 'goods:delete','goods:view' ]"
        >
          <a-menu slot="overlay">
            <a-menu-item key="modify" @click="modify($event, record)"
                         v-hasPermission="'goods:edit'">
              <a-icon type="setting"/>
              修改
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item
                key="delete"
                v-hasPermission="'goods:delete'"
                style="color: red"
                @click="getDelete($event, record)"
            >
              <a-icon type="delete"/>
              删除
            </a-menu-item>
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyActionModal";

export default {
  name: "ShopActionList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "shop/action/actionList",
      listMethod: "get",
      goodsCateList: [],
      showLoading: false
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "活动宣传图",
          width: 100,
          align: "center",
          dataIndex: "images",
          scopedSlots: {customRender: "masterImage"},
        },

        {
          title: "活动名称",
          align: "center",
          width: 200,
          dataIndex: "actionTitle",
        },

        {
          title: "状态",
          align: "center",
          width: 80,
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
        },

        {
          title: "活动说明",
          align: "center",
          width: 200,
          dataIndex: "remark",
        },
        {
          title: "开始时间",
          align: "center",
          width: 160,
          dataIndex: "beginTime",
        },
        {
          title: "结束时间",
          align: "center",
          width: 160,
          dataIndex: "endTime",
        },
        {
          title: "参与活动商品ID",
          align: "center",
          width: 200,
          dataIndex: "goodsIdList",
          customRender: (text, row, index) => {
            return (row.goodsIdList || []).join(',')
          }
        },
        {
          title: "操作",
          width: 120,
          align: "center",
          fixed: "right",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
    cateList() {
      let cateType = 5;//类型（1--圈子分类，2--手册分类，3--评测分类,4--省钱宝分类）
      return this.goodsCateList.filter(v => v.type === cateType).map(v => {
        return {
          value: v.cateId,
          label: v.cateName
        }
      })
    }
  },

  mounted() {
    this.search();
    this.$postJson('/category/cateList', {pageSize: 100, type: 5}).then(res => {
      this.goodsCateList = ((res.data && res.data.records) || [])
    })
  },
  methods: {
    editSuccess() {
      this.search();
    },
    modify(e, record) {
      this.$refs.modifyModal.showModal(record, 'edit');
    },
    add() {
      this.$refs.modifyModal.showModal(null, 'new');
    },
    getDelete(e, record) {
      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除【" + record.circleName + "】分类吗？！",
        centered: true,
        onOk() {
          that.$postJson("category/delete", [record.cateId]).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
    changeTopState(record, field = 'isTop') {
      let that = this;
      let data = {
        id: record.id,
      };
      data[field] = record[field] == 1 ? 0 : 1;
      that.showLoading = true;
      that.$postJson("goods/save", data).then(() => {
        that.showLoading = false;
        let mes =
            record[field] != 1
                ? "ID：【" + record.id + "】设置" + (field === 'isTop' ? "推荐" : "热门") + "成功"
                : "ID：【" + record.id + "】取消" + (field === 'isTop' ? "推荐" : "热门") + "成功"
        that.$message.success(mes + "，请前往小程序查看");
        this.search();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
